import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faLinkedin, faGithub, faMailchimp } from '@fortawesome/free-brands-svg-icons';
import { faInbox} from '@fortawesome/free-solid-svg-icons'
import lottie from 'lottie-web'
import meditime from './image/meditime.png'
import tame from './image/tame.png'
import gametag from './image/gametag.png'
import sporttag from './image/sporttag.png'
import lapak from './image/lapaksahabat.png'
import tamil from './image/thinktamil.png'

const navbarTypes = ["Home", "Work"];
function Navbar() {
  const [active, setActive] = useState(navbarTypes[0]);
  const buttonCliced = (type) => {
    setActive(type)
  }

  return(
    <div>
      {navbarTypes.map((type) => (
        <a href={`#${type}`}>
          <button 
            key={type}
            onClick={() => buttonCliced(type)}
            value={type}
            className={active === type ? "btn-navbar" : 'btn-navbar-noactive'}
          >
            {type} 
          </button> 
      </a>
      ))}
    </div>
  )
}

const meTypes = ["Me", "Work"];
function MeButton() {
  const [active, setActive] = useState(meTypes[0]);
  const buttonCliced = (type) => {
    setActive(type)
  }
  
  const Detail = () => {
    if (active == "Me") {
      return (
        <div>
          <h5 className="text-color-blue">ME</h5>
          <p>ME</p>
        </div>
      )
    }else{
      return (
        <div>
          <h5 className="text-color-blue">Work</h5>
          <p>WORK</p>
        </div>
      )
    }
  }

  return (
    <div>
      <div className="row">
          <div className="col-sm-12">
            <div className="row">
              <div className="col-sm-3 col-button">
                {meTypes.map((type) => (
                  <button 
                    key={type}
                    onClick={() => buttonCliced(type)}
                    value={type}
                    className={active === type ? "btn-about" : 'btn-about-noactive'}
                  >
                    {type} 
                  </button> 
                ))}
              </div>
              <div className="col-sm-9 col-detailme">
                <Detail />
              </div>
            </div>
          </div>
        </div>
    </div>
  );
}

const workTypes = [ "Mobile Apps", "Website"];
function WorkButton(){
  const [active, setActive] = useState(workTypes[0]);
  const buttonCliced = (type) => {
    setActive(type)
  }

  const Detail = () => {
    if (active == "Mobile Apps") {
      return (
        <div>
          <h5 className="text-color-blue">Design Review</h5>
          <p>Mobile Apps</p>
        </div>
      )
    }else{
      return (
        <div>
          <h5 className="text-color-blue">Design Review</h5>
          <p>Website</p>
        </div>
      )
    }
  }

  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="text-center">
          {workTypes.map((type) => (
            <button 
              key={type}
              onClick={() => buttonCliced(type)}
              value={type}
              className={active === type ? "btn-work" : 'btn-work-noactive'}
            >
              {type} 
            </button> 
          ))}
        </div>
      </div>
      <div className="col-sm-12">
        <Detail />
      </div>
    </div>
  )
}

const datas = [
  {
    nama: "GameTag",
    gambar: gametag,
    deskripsi: "Access to hundreds of games catalog from Action to Horror containing details and also search and save of favorite item for you",
    durasi: "August 17, 2021 - Sept 13, 2021",
    bahasa: "SwiftUI",
    tech: "SwiftUI, List, UserDefaults, CoreData Relationships, URLSessions, Completion Handler, Combine, Pods, SDWebImage, SwiftLint, MVVM",
    links: [
      {
        name: "github",
        link: "https://github.com/maitrivira/GamesTag.git",
      }
    ]
  },
  {
    nama: "Meditime",
    gambar: meditime,
    deskripsi: "Track, remind and schedule your daily medicine for better health. Being caregiver or for you, well-being is for you",
    durasi: "July 5, 2021 - August 12, 2021",
    bahasa: "Swift",
    tech: "UIKit, Storyboard XIB, TableView, CollectionView, CoreData, UserNotification, AVFoundation, MVC",
    links: [
      {
        name: "github",
        link: "https://github.com/maitrivira1/MediTime.git",
      },
      {
        name: "app store",
        link: "https://apps.apple.com/us/app/meditime/id1579997303#?platform=iphone",
      }
    ]
  },
  {
    nama: "SportTag",
    gambar: sporttag,
    deskripsi: "See list type of sports, everything from Soccer to Water Sport. With all details story, picture. And get personalized recommendations just for you",
    durasi: "May 25, 2021 - May 31, 2021",
    bahasa: "Swift",
    tech: "UIKit, Storyboard XIB, TableView, URLSession, Completion Handler, Pods, SkeletonView, MVVM",
    links: [
      {
        name: "github",
        link: "https://github.com/maitrivira/SportTag.git",
      }
    ]
  },
  {
    nama: "Tame",
    gambar: tame,
    deskripsi: "Simple interface for medicine reminder just select the time of your schedule and reminding you on time",
    durasi: "March 29, 2021 - April 11, 2021",
    bahasa: "Swift",
    tech: "UIKit, Storyboard, Auto Layout, TableView, UserNotification, MVC",
    links: [
      {
        name: "github",
        link: "https://github.com/maitrivira/Tame.git",
      }
    ]
  },
  {
    nama: "ThinkTamil",
    gambar: tamil,
    deskripsi: "Get access to incredibly fun games such as language game, letter game, all for sharpen, memory, thinking your child",
    durasi: "Sept 2020 - Nov 2020",
    bahasa: "React JS",
    tech: "ReactJS, Prop-Types, Async Await, Redux, Axios, Bootstrap, Eslint",
    links: []
  },
  {
    nama: "LapakSahabat",
    gambar: lapak,
    deskripsi: "Lapak Sahabat is an app that can be used for sending money, payment PPOB about Islamic. As a Frontend Developer, I made the UI based on designer",
    durasi: "Feb 2020 - June 2020",
    bahasa: "Flutter",
    tech: "",
    links: []
  }
]
function Work() {
  return(
    <div className="container">
      
      {datas.map((data) => (
        <div className="row py-3">
          <div className="col-lg-8">
            <div className="image">
              <img draggable="false" src={`${data.gambar}`} alt={data.gambar} width="100%" height="auto"/>
            </div>
          </div>
          <div className="col-lg-4">
            <h5 className="text-color-blue" style={{margin : 0}}><b>{data.nama}</b></h5>
            <div>{data.durasi}</div>
            <div className="py-3 text-color-blue">{data.deskripsi}</div>
            <div>{data.tech}</div>
            <div className="py-3">
              {
                data.links.map((link) => (
                  <a href={link.link} target="_blank">
                    <button className="btn-work">{link.name}</button>
                  </a>
                ))
              }
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

function App() {
  const [navbar, setNavbar] = useState(false);
  const container = useRef(null);
  const today = new Date();
  const year = today.getFullYear();

  useEffect(() => {
    lottie.loadAnimation({
      container: container.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: require("./anim.json")
    });
  }, []);

  const changeBackground = () => {
    if (window.scrollY >= 650) {
      setNavbar(true)
    }else{
      setNavbar(false)
    }
  }

  window.addEventListener('scroll', changeBackground);
  
  return (
    <div className="App" id="Home">

      {/* BODY 1 Homepage*/}
      <div className="App-header position-relative">

        <div className={`container-fluid top ${navbar ? 'fixed-top navbar-fixed' : ''}`}>
          <div className="row navbar-bar">
            <div className="col-md-12">
              <Navbar/>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row px-3">
            <div className="col-md-6 align-self-center">
              <div className="row">
                <div className="col-md-12">
                  <h1>Hi! I'm Maitri Vira,</h1>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <h5>Let's collaborate to make incredible things.</h5>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <a href="#Work"><button className="btn-home-work">See My Work</button></a>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div ref={container} />
            </div>
          </div>
        </div>

        <div className="container-fluid bottom">
          <div className="row px-4 py-3">
            <div className="col-md-12">
              <div className="icon">
                <a href="https://www.instagram.com/maitri_vira/" target="_blank" style={{color: "white"}} className="fa-instagram px-2"><FontAwesomeIcon icon={faInstagram} className="mx-2 fa-lg"/></a>
                <a href="https://www.linkedin.com/in/maitri-vira" target="_blank" style={{color: "white"}} className="fa-linkedin px-2"><FontAwesomeIcon icon={faLinkedin} className="mx-2 fa-lg"/></a>
                <a href="https://github.com/maitrivira" target="_blank" style={{color: "white"}} className="fa-facebook px-2"><FontAwesomeIcon icon={faGithub} className="mx-2 fa-lg"/></a>
              </div>
              <div className="copyright">
                Maitri Vira | &copy; {year} All Rights Reserved
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* BODY 2 Work*/}
      <div className="container py-5" id="Work">
        <div className="row">
          <div className="col-sm-12 py-5">
            <div className="text-title">
              <h2 className="text-color-blue"><b>My Work</b></h2>
              <h6>Here my journey building mobile app and website page</h6>
            </div>
          </div>
        </div>
        <Work/>
      </div>

      {/* BODY 3 self */}
      <div className="container py-5" id="About">
        <div className="row px-3">
          <div className="col-sm-12 py-5 text-center">
            <h6 className="text-color-blue"><a href="" style={{color: "black"}} className="fa-instagram px-2"><FontAwesomeIcon icon={faInbox} className="mx-2 fa-lg"/></a> maitrivira2@gmail.com</h6>
            <h6 className="text-color-blue"><a href="https://github.com/maitrivira" style={{color: "black"}} className="fa-instagram px-2"><FontAwesomeIcon icon={faGithub} className="mx-2 fa-lg"/></a> maitrivira</h6>
            <h6 className="text-color-blue"><a href="https://www.instagram.com/maitri_vira/" style={{color: "black"}} className="fa-instagram px-2"><FontAwesomeIcon icon={faInstagram} className="mx-2 fa-lg"/></a> maitri_vira</h6>
            <h6 className="text-color-blue"><a href="https://www.linkedin.com/in/maitri-vira" style={{color: "black"}} className="fa-instagram px-2"><FontAwesomeIcon icon={faLinkedin} className="mx-2 fa-lg"/></a> Maitri Vira</h6>
          </div>
        </div>
      </div>
        
      {/* FOOTER */}
      {/* <footer className="container-fluid">
        <div className="row px-4 py-3 footer">
          <div className="col-md-12">
            <div className="icon">
              <a href="https://www.instagram.com/maitri_vira/" style={{color: "white"}} className="fa-instagram px-2"><FontAwesomeIcon icon={faInstagram} className="mx-2 fa-lg"/></a>
              <a href="https://www.linkedin.com/in/maitri-vira" style={{color: "white"}} className="fa-linkedin px-2"><FontAwesomeIcon icon={faLinkedin} className="mx-2 fa-lg"/></a>
              <a href="https://github.com/maitrivira" style={{color: "white"}} className="fa-facebook px-2"><FontAwesomeIcon icon={faGithub} className="mx-2 fa-lg"/></a>
            </div>
            <div className="copyright">
              Maitri Vira | &copy; 2019 All Rights Reserved
            </div>
          </div>
        </div>
      </footer> */}
    </div>
  );
  
}

export default App;
